import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { 
    
  Input, Button, Grid, Heading, HStack, Center, Highlight, useToast, Card, CardBody, Text, Badg, VStack,

  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
} from '@chakra-ui/react';

import { ChatIcon, ArrowForwardIcon } from '@chakra-ui/icons'


export function SuccessPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const MonoTypeProblem = (e) => {
    navigate('/problem-result', 
    { state: {problem_type: 'mono'} }
    );
  };


  useEffect(() => {
    // TODO: 쿼리 파라미터 값이 결제 요청할 때 보낸 데이터와 동일한지 반드시 확인하세요.
    // 클라이언트에서 결제 금액을 조작하는 행위를 방지할 수 있습니다.
    const requestData = {
      token: window.localStorage.getItem("token"),
      orderId: searchParams.get("orderId"),
      amount: searchParams.get("amount"),
      paymentKey: searchParams.get("paymentKey"),
    };

    async function confirm() {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/payments/confirm`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      const json = await response.json();

      if (!response.ok) {
        // TODO: 결제 실패 비즈니스 로직을 구현하세요.
        console.log(json);
        navigate(`/fail?message=${json.message}&code=${json.code}`);
        return;
      }
      window.localStorage.setItem('subscription', true)
      console.log(json);
    }
    confirm();
  }, []);



  return (
    <Grid
      templateColumns={{ md: "1fr", lg: "1fr" }}
      templateRows={{ md: "1fr auto", lg: "1fr" }}
      justifyContent={{ md: "center", lg: "center", xl: "center" }}
      my="26px"
      gap="24px"
      padding="5"
    >
       <Heading lineHeight='tall'>
        <Highlight
          query={['결제']}
          styles={{ px: '2', py: '1', rounded: 'full', bg: 'blue.100' }}
        >
          성공적으로 결제가 완료되었습니다!
        </Highlight>
      </Heading>
    <div className="result wrapper">
      <div className="box_section">
        <h2 style={{ padding: "20px 0px 10px 0px" }}>
          <Center>
          <img
            width="50px"
            src="https://static.toss.im/3d-emojis/u1F389_apng.png"
          />
            <p><Text as='b'>{`결제 금액: ${Number(
          searchParams.get("amount")
        ).toLocaleString()}원`}</Text></p>
          </Center>
          <Center>
<Button 
  size='md'
  height='48px'
  width='300px'
  variant='link'
  colorScheme='blue'
  borderRadius={'3xl'}
  onClick={MonoTypeProblem} 
  rightIcon={<ArrowForwardIcon />} 
>
  추천 문제 풀어보러 가기
</Button>
</Center>
        </h2>
        {/* <p>{`주문번호: ${searchParams.get("orderId")}`}</p> */}
      
        {/* <p>{`paymentKey: ${searchParams.get("paymentKey")}`}</p> */}
      </div>
    </div>
    </Grid>  

  );
}
