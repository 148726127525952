import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import {
  Box, Button, Card, Center, Grid, Heading, IconButton, Image, SimpleGrid, VStack, HStack, Stack, Skeleton,
  RadioGroup, Radio, Spacer, Badge, Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent,
  DrawerCloseButton, useDisclosure, Text, CardBody, CardFooter,CardHeader, Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
} from '@chakra-ui/react';
import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css";

import { ArrowForwardIcon, CheckCircleIcon, CloseIcon, LockIcon, RepeatIcon} from '@chakra-ui/icons';
import { FaRobot } from "react-icons/fa6";
import BeatLoader from "react-spinners/BeatLoader";
import { useToast } from '@chakra-ui/react'
import { CgBookmark, CgSandClock } from "react-icons/cg";
import { Progress } from '@chakra-ui/react'
import { MdBookmark, MdBookmarkBorder, MdRestartAlt} from "react-icons/md";
import CanvasDraw from "react-canvas-draw";

function ProblemType() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [problem, setProblem] = useState();
  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [progress, setProgress] = useState(0);
  const [isCorrect, setIsCorrect] = useState(false);
  const toast = useToast()
  const [timeLeft, setTimeLeft] = useState(60000); // 60초를 밀리초 단위로 초기화
  const [problemCount, setProblemCount] = useState(parseInt(localStorage.getItem("session_problem_count") || "0"));
  const { isOpen: isPaymentModalOpen, onOpen: onPaymentModalOpen, onClose: onPaymentModalClose } = useDisclosure(); // 결제 프롬프트에 대해 추가되었습니다.
  const [isBookmark, setBookmark] = useState(false);

  useEffect(() => {

    if (location.state.problem_type === 'bookmarks') {
      let url = `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/bookmarks/problem?token=${window.localStorage.getItem('token')}`;
      axios.get(url)
        .then(response => {
          setProblem(response.data);
        })
        .catch(error => {
          console.log(error);
          navigate('/engage-problem')
        });
      setBookmark(true)
    }
    
    else if (location.state.problem_type === 'false') {
      let url = `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/false/?token=${window.localStorage.getItem('token')}`;
      axios.get(url)
        .then(response => {
          setProblem(response.data);
        })
        .catch(error => {
          console.log(error);
          navigate('/engage-problem')
        });
    }

    else {
      let url = `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/problem/?problem_type=${location.state.problem_type}`;
      axios.get(url)
        .then(response => {
          setProblem(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    }

   

    }, [location.state.problem_type]);

  useEffect(() => {
    // This effect updates the progress bar whenever the problem count changes.
    setProgress((problemCount / 5) * 100);
  }, [problemCount]);

  function clickBookmark(){
    setBookmark((prevbookmark) => { 
        if (prevbookmark) {
            let url = `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/bookmarks/problem?problem_id=${problem.id}&bookmark=${prevbookmark}&token=${window.localStorage.getItem('token')}`;
            axios.patch(url)
            console.log(prevbookmark)
            return false

        }
        else {
            let url = `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/bookmarks/problem?problem_id=${problem.id}&bookmark=${prevbookmark}&token=${window.localStorage.getItem('token')}`;
            axios.patch(url)
            console.log(prevbookmark)
            return true
        }
      })
}


  const handleSubmit = () => {
    if (problem) {
      setIsCorrect(parseInt(selectedAnswer) === problem.answer);
      // Increment problem count in local storage
      let newCount = problemCount;
      if(newCount >= 3 && !window.localStorage.getItem('subscription')) {
        // 5개의 문제를 풀었다면, 결제 프롬프트를 엽니다.
        newCount = 3;
        onPaymentModalOpen();
      } else {
        // 그렇지 않다면, 이전처럼 결과를 보여줍니다.
        onOpen();
      }
      localStorage.setItem("session_problem_count", newCount.toString());
      setProblemCount(newCount);
      // 결제 프롬프트가 열려 있을 때는 즉시 navigate(0)을 하지 않습니다.

      const apiUrl = `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/session/study/`; // Django API 엔드포인트로 변경하세요.
      console.log(parseInt(selectedAnswer) === problem.answer)
      axios.post(apiUrl, 
        { 
          token: localStorage.getItem('token'),
          correct: parseInt(selectedAnswer) === problem.answer,
          problem: problem.id

           })
        .then(response => {
          // 성공적으로 제출되었을 때의 로직
          console.log('result submitted successfully:', response.data);
        })
        .catch(error => {
          // 오류 처리 로직
          console.error('result', error);
         
        });

        toast({
          title: '15포인트를 획득하셨어요!',
          position: 'top',
          description: "문제를 더 풀고 포인트를 더 획득하세요!",
          status: 'info',
          duration: 1500,
          isClosable: true,
        })
        const pointUrl = `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/points/`; // Django API 엔드포인트로 변경하세요.
        axios.post(pointUrl, 
          { 
            token: localStorage.getItem('token'),
            point: 15,
            type: 'problem'
  
             })
          .then(response => {
            // 성공적으로 제출되었을 때의 로직
            console.log('result submitted successfully:', response.data);
            
          })
          .catch(error => {
            // 오류 처리 로직
            console.error('result', error);
           
          });
    }
  };

  const AISolution = () => {
    navigate('/problem-solution-result', 
    { state: {problem_id: problem.id,
              problem_image: problem.image} }
    );
  }

  const NextProblem = () => {
    let newCount = problemCount + 1;
    localStorage.setItem("session_problem_count", newCount.toString());
    setProblemCount(newCount);
    navigate(0);
  };

  const subscribeCheckout = (e) => {
    navigate('/checkout-month');
  };

  return (
    <>
    <Grid templateColumns="repeat(auto-fit, minmax(300px, 1fr))" gap={6} p={5}>
      {problem ? (
        <VStack spacing={8} align="stretch">
                    <HStack width="100%" spacing={3}>
                     {/* <Text width="15%" textAlign="center">{problemCount}/10</Text> */}
         <Progress value={(problemCount ==10 ? 1 : (problemCount % 10) / 10) * 100} size="md" colorScheme="blue"  width="100%"/>
                    </HStack>

          {/* <HStack width="100%" spacing={3}> */}
            {/* <Icon width="5%" as={CgSandClock} boxSize={6}/> */}
            {/* <Text width="10%" textAlign="center">{(timeLeft / 1000).toFixed(1)}</Text> 남은 시간을 초 단위로 변환하고 소수점 둘째 자리까지 표현 */}
            {/* <Progress value={(timeLeft / 60000) * 100} size="md" colorScheme="blue" width="85%" /> */}
          {/* </HStack> */}
          
          <Card align="center" 
          variant="filled" 
          colorScheme="blue"
          overflow="scroll">
            <CardBody align="right">
            <IconButton 
              fontSize='30px'

              onClick={clickBookmark} aria-label='Book Mark'
               icon={isBookmark ? <MdBookmark />: <MdBookmarkBorder/>}  /> 

                {problem.html_text ? (
                       <div
                       style={{
                         textAlign: "left",
                         fontSize: "0.9em", // 글자 크기를 작게 조정
                       }}
                     >
                       <div
                         dangerouslySetInnerHTML={{ __html: problem.html_text }}
                       ></div>
                       <br></br>
                     </div>
                    ) : (
            <Image src={problem.image} borderRadius="lg" /> )}
            </CardBody>
          </Card>
          <Card overflow={"hidden"} align="center" variant="unstyled" colorScheme="blue">
          <CardBody align="right">
          <CanvasDraw
           brushRadius={1}
           lazyRadius={1}
           canvasWidth={2400}
           canvasHeight={400}
          />
          </CardBody>
          </Card>
            <VStack>
            <RadioGroup onChange={setSelectedAnswer} value={selectedAnswer}>
              <Stack direction="row" mb="4">
                <Radio value="1">1번</Radio>
                <Radio value="2">2번</Radio>
                <Radio value="3">3번</Radio>
                <Radio value="4">4번</Radio>
                <Radio value="5">5번</Radio>
              </Stack>
            </RadioGroup>
            <Button colorScheme="blue" onClick={handleSubmit}>
              제출
            </Button>
            </VStack>
            <br/><br/>
          <Drawer placement="bottom" onClose={onClose} isOpen={isOpen} closeOnOverlayClick={false}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader borderBottomWidth="1px">
                {isCorrect ? (
                  <Stack direction="row" spacing={3} align="center">
                    <CheckCircleIcon color="green.500" />
                    <Text fontSize="lg" fontWeight="bold" color="green.500">정답입니다!</Text>
                  </Stack>
                ) : (
                  <Stack direction="row" spacing={3} align="center">
                    <CloseIcon color="red.500" />
                    <Text fontSize="md" fontWeight="bold" color="red.500">정답은 {problem.answer}번입니다.</Text>
                    <Spacer/>
                    <Button leftIcon={<RepeatIcon/>} variant='outline' mr={3} onClick={onClose}>
                  다시 풀기
              </Button>
                  </Stack>
                )}
              </DrawerHeader>
              <DrawerBody>
                <HStack>
                <Button rightIcon={< FaRobot/>} variant='outline' mr={3}  onClick={AISolution}>
                  AI 해설지 보기
              </Button>
                <Spacer/>
                <Button rightIcon={<ArrowForwardIcon />} colorScheme="blue" onClick={NextProblem}>
                  다음 문제
                </Button>
              </HStack>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </VStack>
      ) : (
        <Center>
          <BeatLoader />
        </Center>
      )}
    </Grid>
    <Modal isOpen={isPaymentModalOpen} onClose={onPaymentModalClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>구독 하러가기</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        3문제를 해결했습니다! 계속하려면 구독해주세요.
      </ModalBody>
      <ModalFooter>
        <Button colorScheme="blue" mr={3} onClick={onPaymentModalClose}>
          닫기
        </Button>
        <Button variant="ghost" onClick={subscribeCheckout}>구독하기</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
  </>
  );
}

export default ProblemType;
