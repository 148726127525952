// Chakra imports
import {
    Flex,
    Grid,
    Image,
    SimpleGrid,
    useColorModeValue,
    Box,
    Text,
    VStack,
    HStack,
    Spacer,
    IconButton,
    Icon,
    Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  } from "@chakra-ui/react";
  
  import { Card, CardHeader, CardBody } from '@chakra-ui/react'
  import { Heading } from '@chakra-ui/react'
  import { useState } from "react";
  import axios from 'axios';
  import { Button, ButtonGroup } from '@chakra-ui/react'
  import { useNavigate } from 'react-router-dom';  
  import React from "react";
  import { FileUploader } from "react-drag-drop-files";
  import { ChatIcon, InfoIcon } from '@chakra-ui/icons'
  import { CiImageOn, CiEdit } from "react-icons/ci";
  import UploadFileListCard from "./UploadFileListCard";
  import { CiExport } from "react-icons/ci";

  const fileTypes = ["JPEG", "PNG", "GIF"];
  
  
  export default function CreateSolutionDashboard() {
    const [file, setFile] = useState(null);
    const [isloading, setLoading] = useState(false);
    const [solutionCount, setSolutionCount] = useState(
      parseInt(window.localStorage.getItem("solutionCount") || "0")
    );

    const { isOpen, onOpen, onClose } = useDisclosure();
  
    const navigate = useNavigate();
    const UploadField =  (
    <VStack spacing={3}>
    {/* <img
      className="feather-upload-cloud"
      alt="Feather upload cloud"
      src={Feather}
    /> */}
      <Text fontSize='md'>해설이 필요한 수학 문제를 AI에게 물어보세요!</Text>
      <Text fontSize='xs'  color='gray'>수학 문제 이미지 파일을 올려주세요.</Text>
      <Icon as={CiExport} boxSize={5} />
       </VStack>
  );
  const handleChange = (file) => {
    setFile(file);
  };

  const handleIntroPage = (e) => {
    navigate('/chaticon-landing');
  };

  const subscribeCheckout = (e) => {
    navigate('/checkout-month');
  };
  
  const handleSubmit = (e) => {
    setLoading((prevLoading) => { return true})
    e.preventDefault();
    if (solutionCount >= 5 && !window.localStorage.getItem('subscription')) {
      // Prevent further processing and inform the user
      onOpen();
    }


    else {
      console.log(file);
      let form_data = new FormData();
      form_data.append('image', file);
      form_data.append('title', file.name);
  
      let url = `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/solution/problem_capture/`;
      axios.post(url, form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
          .then(res => {
            console.log(res.data);
            navigate('/create-solution-result', 
            { state: {problem_id: res.data.id, html_text: res.data.html_text} }
            );
            const newCount = solutionCount + 1;
            setSolutionCount(newCount);
            window.localStorage.setItem("solutionCount", newCount.toString());
          })
          .catch(err => console.log(err))
    }
   
  };
  
    return (
      <>
        <Grid
        templateColumns={{ md: "1fr", lg: "1fr" }}
        templateRows={{ md: "1fr auto", lg: "1fr" }}
        justifyContent={{ md: "center", lg: "center", xl: "center" }}
        my="26px"
        gap="24px"
        padding="5"
        marginTop={0}
        >
 <HStack > 
        <Heading textAlign={"left"} size='md'>AI 문제 풀이</Heading>
          <Spacer />

           <IconButton
             onClick={handleIntroPage}
             isRound={true}
             variant='solid'
             colorScheme='blue'
          aria-label='Send email'
          icon={<ChatIcon />}
        /></HStack>
  
  <Card align='center' variant={'outline'}>
    <CardBody>
    <form onSubmit={handleSubmit}>
          <FileUploader
          children={UploadField}
          multiple={false}
          handleChange={handleChange}
          name="file"
          types={fileTypes}
        />
  {file ?
         <UploadFileListCard 
            title={"업로드 된 파일"}
            captions={["파일 이름"]}
            data={file}
          />
          
          :""}
        {file && !isloading? 
       <Button
              mt={4}
              colorScheme='blue'
              // isLoading={props.isSubmitting}
              type='submit'
              leftIcon={<CiEdit />}
            >
              해설지 생성
            </Button>: <></>} 
            {!isloading?
          <></> :  <Button mt={4} isLoading colorScheme='blue' variant='solid'>
          해설지 생성
        </Button>
          }
        {/* <Button colorScheme='blue'><input type="submit"/></Button> */}
          </form>
    </CardBody>
  </Card>
        </Grid>
         <Modal isOpen={isOpen} onClose={onClose}>
         <ModalOverlay />
         <ModalContent>
           <ModalHeader>구독 하러가기</ModalHeader>
           <ModalCloseButton />
           <ModalBody>
             5문제를 해결했습니다! 계속하려면 구독해주세요.
           </ModalBody>
           <ModalFooter>
             <Button colorScheme="blue" mr={3} onClick={onClose}>
               닫기
             </Button>
             <Button variant="ghost" onClick={subscribeCheckout}>구독하기</Button>
           </ModalFooter>
         </ModalContent>
       </Modal>
       </>
    );
  }
  