import React, { useState } from "react";
import axios from "axios";
import { Input, Button, Grid, Heading, HStack, Center, Highlight, useToast, Text } from '@chakra-ui/react';

function Refund() {
  return (
    <Grid
      templateColumns={{ md: "1fr", lg: "1fr" }}
      templateRows={{ md: "1fr auto", lg: "1fr" }}
      justifyContent={{ md: "center", lg: "center", xl: "center" }}
      my="26px"
      gap="24px"
      padding="5"
    >
       <HStack > 
          <Heading textAlign={"left"} size='md'>환불규정</Heading>
          {/* <Spacer /> */}
          </HStack>
          <Text align={"left"}>구독자는 구독 시작일 이후 정기 구독 서비스를 1회라도 사용하셨거나, 구독 시작일 이후 7일이 지난 경우 구독 결제를 철회할 수 없습니다.</Text>
          <Text align={"left"}>만약 구독자가 1회미만 사용시, 시작일 이후 7일 이내 철회를 원하실 경우 회사는 7 영업일 내에 전액 환불드립니다.</Text>
      <HStack>

        {/* <Button colorScheme='gray' variant='outline' onClick={handleSubmit}>
          구독 취소
        </Button> */}
      </HStack>
   
    </Grid>  
  );
}

export default Refund;
