import React, { useState } from "react";
import axios from "axios";
import { Input, Button, Grid, Heading, HStack, Center, Highlight, useToast, VStack } from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";

function Ranking() {
  const [email, setEmail] = useState('');
  const toast = useToast(); // useToast Hook을 사용하여 toast 인스턴스 생성
  const navigate = useNavigate();

  const handleSubmit = () => {
    const apiUrl = `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/email-subscription/`; // Django API 엔드포인트로 변경하세요.
    axios.post(apiUrl, { email: email })
      .then(response => {
        // 성공적으로 제출되었을 때의 로직
        console.log('Email submitted successfully:', response.data);
        toast({ // 성공 메시지 팝업
          description: "사전 신청이 등록되었어요",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
          colorScheme: "blue"
        });
      })
      .catch(error => {
        // 오류 처리 로직
        console.error('There was an error submitting the email:', error);
        toast({ // 오류 메시지 팝업
          description: "이미 사전 신청된 번호입니다.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      });
  };

  const handleIntroPage = () => {
    window.location.href = 'https://lambda-services.notion.site/MathExodus-daa273193d07458196f5b07b39b55d6f';
  };

  const handlePrePage = () => {
    navigate('/problem');
  };

  return (
    <Grid
      templateColumns={{ md: "1fr", lg: "1fr" }}
      templateRows={{ md: "1fr auto", lg: "1fr" }}
      justifyContent={{ md: "center", lg: "center", xl: "center" }}
      my="26px"
      gap="24px"
      padding="5"
    >
      <Heading lineHeight='tall'>
        <Highlight
          query={['사전 신청', '혜택']}
          styles={{ px: '2', py: '1', rounded: 'full', bg: 'blue.100' }}
        >
          지금 바로 학년 서비스 사전 신청 알림을 받아보세요!
        </Highlight>
      </Heading>

      <HStack>
        <Input placeholder='이메일 주소 혹은 휴대폰 번호를 입력해주세요!' size='sm' value={email} onChange={(e) => setEmail(e.target.value)} />
        <Button colorScheme='blue' variant='outline' onClick={handleSubmit}>
          제출
        </Button>
      </HStack>
      <Center>
        <VStack>
        <Button
         size='md'
         height='48px'
         width='300px'
         variant='outline'  
         colorScheme='blue'
         borderRadius={'3xl'}
          onClick={handleIntroPage}
        >
          MathExodus 소개 페이지
        </Button>
        <Button
         size='md'
         height='48px'
         width='300px'
         variant='outline'  
         colorScheme='blue'
         borderRadius={'3xl'}
          onClick={handlePrePage}
        >
          고3 서비스 체험하기
        </Button>
        </VStack>
      </Center>
    </Grid>  
  );
}

export default Ranking;
