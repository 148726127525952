import React, { useState, useEffect } from 'react';
import {
  Flex,
  Grid,
  Box,
  Heading,
  Text,
  Input,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  HStack,
  useToast,
  VStack,
  IconButton,
  Divider,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
import { AiOutlineLike, AiOutlineDislike } from "react-icons/ai";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export function Problem() {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  // 기본적으로 '전체' 카테고리를 선택된 상태로 설정
  const [category, setCategory] = useState("전체");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const toast = useToast();
  const navigate = useNavigate();

  // 데이터 가져오기 함수
  const fetchData = async (category, searchTerm, page) => {
    setLoading(true);
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/problem_generator/public_passages/`, {
        params: {
          category: category === "전체" ? undefined : category,
          search: searchTerm || undefined,
          page: page,
        },
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
      setData(response.data.results);
      setTotalPages(Math.ceil(response.data.count / 10)); // 총 페이지 수 계산
    } catch (error) {
      console.error("Error fetching data", error);
      toast({
        title: "데이터 가져오기 실패",
        description: "데이터를 가져오는 중 오류가 발생했습니다.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(category, searchTerm, currentPage);
  }, [category, searchTerm, currentPage]);

  // 카테고리 필터링 핸들러
  const handleCategorySelect = (category) => {
    setCategory(category === "전체" ? "" : category);
    setCurrentPage(1);
  };

  // 검색어 입력 핸들러
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    fetchData(category, e.target.value, 1);
  };

  const categories = ['전체', '뉴스', '경제', '문화/생활', '오피니언', '과학/기술', '인물', '문화예술', '독서'];

  return (
    <Flex
      flexDirection="column"
      pt={{ base: "100px", md: "75px" }}
      px={{ base: 4, md: 8 }}
      bg={useColorModeValue("white", "white")}
    >
      <Grid templateColumns={{ md: "1fr" }} gap="24px">
        <Box mb={8}>
          <Heading size="lg" color="gray.600">
            글을 읽어보세요
          </Heading>
          <Text color="gray.600" mt={2}></Text>
        </Box>

        {/* 카테고리 필터 버튼 */}
        <HStack spacing={4} justify="center" wrap="wrap">
          {categories.map((cat) => (
            <Button
              key={cat}
              colorScheme={category === cat ? "gray" : "gray"}
              onClick={() => handleCategorySelect(cat)}
              variant={category === cat ? "solid" : "outline"}
              borderRadius="full"
              boxShadow="sm"
              fontSize={{ base: "sm", md: "md" }}
              p={{ base: 2, md: 4 }}
            >
              {cat}
            </Button>
          ))}
        </HStack>

        <Box bg="white" borderWidth="1px" borderRadius="xl" p={5} boxShadow="sm">
          <Input
            borderRadius="full"
            placeholder="검색어를 입력하세요"
            value={searchTerm}
            onChange={handleSearch}
            mb={4}
          />

          <Accordion allowToggle>
            {isLoading ? (
              <Text>로딩 중...</Text>
            ) : (
              data.map((passage, index) => (
                <AccordionItem key={index} border="none">
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        {passage.title.split('T')[0]}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <VStack align="left">
                      <Text>{passage.contents}</Text>
                      <HStack justify="space-between">
                        <HStack spacing={2}>
                          <IconButton
                            icon={<AiOutlineLike />}
                            aria-label="Like"
                            variant="outline"
                          />
                          <IconButton
                            icon={<AiOutlineDislike />}
                            aria-label="Dislike"
                            variant="outline"
                          />
                        </HStack>
                      </HStack>
                    </VStack>
                    <Divider my={2} />
                  </AccordionPanel>
                </AccordionItem>
              ))
            )}
          </Accordion>
        </Box>

        {/* 페이지네이션 컨트롤 */}
        <HStack mt={4} spacing={2} justify="center">
          <Button
            colorScheme="gray"
            variant="outline"
            borderRadius="full"
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            isDisabled={currentPage === 1}
          >
            이전
          </Button>
          <Button
            colorScheme="gray"
            variant="outline"
            borderRadius="full"
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            isDisabled={currentPage === totalPages}
          >
            다음
          </Button>
        </HStack>
      </Grid>
    </Flex>
  );
}
