// Chakra imports
import {
    Flex,
    Icon,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Card,
    Box,
    CardHeader,
    Center,
  } from "@chakra-ui/react";
  // Custom components
  import React from "react";
  import UploadFileTableRow from "./UploadFileTableRow";
  
  export default function UploadFileListCard({ title, captions, data }) {
    const textColor = useColorModeValue("gracomponentsy.700", "white");
  
    return (
        <Box maxW='sm' overflow='hidden'>

             <Table variant='simple' color={textColor}>
          <Thead>
            <Tr >
              {captions.map((caption, idx) => {
                return (
                  <Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
                <UploadFileTableRow
                  key={data.name}
                  name={data.name}
                  // size={data.size}
                  type={data.type}
                />
          </Tbody>
        </Table></Box>
    );
  };