import {
    Avatar,
    AvatarGroup,
    Flex,
    Icon,
    Progress,
    Td,
    Text,
    Tr,
    useColorModeValue,
  } from "@chakra-ui/react";
  import React from "react";
  
  export default function UploadFileTableRow(props) {
    const {name, size, type} = props;
    const textColor = useColorModeValue("gray.700", "white");
    return (
      <Tr>
        <Td minWidth={{ sm: "250px" }} pl="0px">
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {name}
            </Text>
          </Flex>
        </Td>
        <Td>
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Text fontSize="sm" color={textColor} >
              {size}
            </Text>
          </Flex>
        </Td>
        <Td>
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Text fontSize="sm" color={textColor} >
              {type}
            </Text>
          </Flex>
        </Td>
      </Tr>
    );
  }
  
  