import React from 'react';
import {
  Box,
  Text,
  VStack,
  Button,
  Input,
  useColorModeValue,
  Heading,
  Container,
  List,
  ListItem,
  ListIcon,
  Icon,
  Grid,
  Highlight,
  Center

} from '@chakra-ui/react';
import { MdCheckCircle } from 'react-icons/md';
  import { useNavigate } from "react-router-dom";

const EventParticipation = () => {
  const bgColor = useColorModeValue('blue.50', 'blue.900');
  const colorScheme = useColorModeValue('blue', 'orange');
  const navigate = useNavigate();

  const handleEventPage = (e) => {
    navigate('/event-engage');
  };

  return (
<Grid
      templateColumns={{ md: "1fr", lg: "1fr" }}
      templateRows={{ md: "1fr auto", lg: "1fr" }}
      justifyContent={{ md: "center", lg: "center", xl: "center" }}
      my="26px"
      gap="24px"
      padding="5"
    >
    <Heading lineHeight='tall'>
    <Highlight
      query={['구독','이벤트']}
      styles={{ px: '2', py: '1', rounded: 'full', bg: 'blue.100' }}
    >
          구독 기념 기프티콘 이벤트! 🎉
        </Highlight>
  </Heading>
    <Container maxW="md" centerContent p={4}>
      <VStack spacing={5} align="stretch" bg={bgColor} p={5} borderRadius="lg" boxShadow="md">
       
        <Text as='b' fontSize="md" textAlign="center">
        이벤트 참여방법</Text>
        <Box>
          <List spacing={2}>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              기프티콘을 받을 연락처를 입력한 후 이벤트 참여하기 버튼을 누르세요!
            </ListItem>
             <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              4/7일 전까지 문제를 풀면서 나의 랭킹 높이기에 도전하세요!
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              4/7일 기준 이벤트 참여자 대상으로 인스타그램에 서비스 사용 인증사진을 인스타에 올리시면 랜덤으로 10분에게 아메리카노 기프티콘을 드립니다~!
            </ListItem>
            {/* <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              4/7일 전까지 문제를 풀면서 나의 랭킹 높이기에 도전하세요!
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              4/7일 기준 이벤트 참여자 대상으로 랭킹이 가장 높은 1,2,3 위 유저에게 기프티콘이 제공됩니다.
            </ListItem> */}
          </List>
        </Box>
      </VStack>
    </Container>
    <Center>
    <Button variant='solid'  color='#0085ff'
          bgColor='#bbdeff'         
           borderRadius={'3xl'}
           onClick={handleEventPage} 
           >
       이벤트 신청하러 가기
      </Button>
      </Center>
    </Grid>
  );
};

export default EventParticipation;
