import React from "react";
import {
  Flex,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Text,
  Heading,
  Button,
  Step,
  Highlight,
  Center,
  Grid,
  SimpleGrid,
  VStack,
  Divider,
  Stack,
  HStack,
  Icon,
} from "@chakra-ui/react";
import { Badge, Input } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FaBookOpen, FaPen, FaAngellist } from "react-icons/fa6";

function Billing() {
  const navigate = useNavigate();

  const handleRefund = (e) => {
    navigate("/policy");
  };

  const handlePrivacy = (e) => {
    navigate("/privacy-policy");
  };

  const handleYearSubs = (e) => {
    navigate("/checkout-year");
  };

  const handleMonthPage = (e) => {
    navigate("/checkout-month");
  };

  const handleEventPage = (e) => {
    navigate("/event");
  };

  return (
    <Grid
      templateColumns={{ md: "1fr", lg: "1fr" }}
      templateRows={{ md: "1fr auto", lg: "1fr" }}
      justifyContent={{ md: "center", lg: "center", xl: "center" }}
      my="26px"
      gap="24px"
      padding="5"
    >
      <Heading lineHeight="tall">
        <Highlight
          query={["저렴한 가격", "모든 서비스"]}
          styles={{ px: "2", py: "1", rounded: "full", bg: "blue.100" }}
        >
          지금 바로 저렴한 가격으로 모든 서비스를 무제한으로 사용해 보세요!
        </Highlight>
      </Heading>
      <Card
        variant={"outline"}
        borderColor={"blue.500"}
        backgroundColor="blue.50"
        onClick={handleYearSubs}
      >
        <CardBody>
          <HStack>
            <Badge colorScheme="blue">1년 구독권</Badge>
            <Badge colorScheme="blue">36,000₩</Badge>
            {/* <Badge alignItems={"right"} colorScheme="blue">
              31% 할인
            </Badge> */}
          </HStack>
        </CardBody>
      </Card>

      <Card variant={"filled"} borderColor={"grey"} onClick={handleMonthPage}>
        <CardBody>
          <HStack>
            <Badge>1달 구독권 </Badge>
            <Badge>3,000₩</Badge>
          </HStack>
        </CardBody>
      </Card>

      {/* <HStack>
<Input placeholder='추천인 코드 입력' />
<Button colorScheme='blue' variant='outline'>
    제출
  </Button>
</HStack> */}

      {/* <Center>

<Button
  size='md'
  height='48px'
  width='300px'
  colorScheme='blue'
  onClick={handleWeeklyPage}

>
일주일간 무료 체험 해보기</Button>
</Center> */}

      <Card
        direction={{ base: "column", sm: "row" }}
        overflow="hidden"
        variant="outline"
        padding={5}
      >
        <Stack m="auto">
          {/* <CardHeader>
    
    </CardHeader> */}
          <CardBody>
            <Center>
              <HStack>
                <Icon as={FaBookOpen} box={10} />
                <Heading size="md">무제한 추천 문제</Heading>
              </HStack>{" "}
            </Center>
            <Text py="2">최신 문제를 제한없이 풀어보세요!</Text>
            <Button
              variant="solid"
              color="#0085ff"
              bgColor="#bbdeff"
              borderRadius={"3xl"}
              onClick={handleMonthPage}
            >
              바로 구독하기
            </Button>
          </CardBody>
          {/* 
    <CardFooter>
      
    </CardFooter> */}
        </Stack>
      </Card>

      <Card
        direction={{ base: "column", sm: "row" }}
        overflow="hidden"
        variant="outline"
        padding={5}
      >
        <Stack m="auto">
          <CardBody>
            <Center>
              <HStack>
                <Icon as={FaPen} box={10} />
                <Heading size="md">AI 문제 해설</Heading>
              </HStack>
            </Center>
            <Text py="2">궁금한 문제 사진을 올려서 AI에게 물어보세요!</Text>
            <Button
              variant="solid"
              color="#0085ff"
              bgColor="#bbdeff"
              borderRadius={"3xl"}
              onClick={handleMonthPage}
            >
              바로 구독하기
            </Button>
          </CardBody>
          {/* <CardFooter align={"center"}>
   
    </CardFooter> */}
        </Stack>
      </Card>

      <Card
        direction={{ base: "column", sm: "row" }}
        overflow="hidden"
        variant={"outline"}
        borderColor={"blue.500"}
        backgroundColor="blue.50"
        // padding={3}
      >
        <Stack m="auto">
          <CardBody>
            <Center>
              <HStack>
                <Icon as={FaAngellist} box={15} />
                <Heading size="md">이벤트 참여</Heading>
              </HStack>
            </Center>
            <Text py="2">
              지금 바로 문제를 풀어보고 랭킹 이벤트에 참여해보세요!
            </Text>
            <Button
              variant="solid"
              color="#0085ff"
              bgColor="#bbdeff"
              borderRadius={"3xl"}
              onClick={handleEventPage}
            >
              이벤트 참여하기
            </Button>
          </CardBody>
          {/* <CardFooter align={"center"}>
   
    </CardFooter> */}
        </Stack>
      </Card>

      <Divider />
      <Stack direction="row" spacing={4}>
        <Button colorScheme="gray" variant="link" onClick={handleRefund}>
          이용약관
        </Button>
        {/* <Button colorScheme="gray" variant="link" onClick={handlePrivacy}>
          개인정보 처리방침
        </Button> */}
        {/* <Button colorScheme='gray' variant='link'>
    문의하기
  </Button> */}
      </Stack>
      <>
        {/* (주)람다 서비시즈
<br/>
대표이사 최대복
<br/>
사업장 주소 경기도 군포시 공단로 356-54 (산본동) 1-1902
<br/>
사업자 등록번호 260-09-02715
<br/>
통신판매업신고번호 
<br/> */}
        <Text align={"left"}>(주)람다 서비시즈</Text>
        <Text align={"left"} as="sub">
          대표자 최대복
        </Text>
        {/* <Text align={"left"} as='sup'>문의처 cdb153@naver.com</Text> */}
        <Text align={"left"} as="sub">
          사업장 주소 경기도 군포시 공단로 356-54 (산본동) 1-1902
        </Text>
        <Text align={"left"} as="sup">
          전화번호 010-5597-1556
        </Text>
        <Text align={"left"} as="sub">
          사업자 등록번호 260-09-02715
        </Text>
        <Text align={"left"} as="sup">
          통신판매업신고번호 제2024-경기군포-0291
        </Text>
      </>
      <br />
      <br />
    </Grid>
  );
}
export default Billing;
