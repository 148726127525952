import React from "react";
import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  Grid,
  VStack,
  Card,
  CardHeader,
  CardBody,
  useColorModeValue,
  Heading,
  HStack,
  Spacer,
  IconButton,
  Center,
  Box,
  Text,
  } from '@chakra-ui/react'
  import { useNavigate } from "react-router-dom";
  import { useState, useEffect } from "react";
  import { ChatIcon, InfoIcon } from '@chakra-ui/icons'
  import { CiTrophy } from "react-icons/ci";
  import { Icon } from '@chakra-ui/react'

  import axios from "axios";
  import BeatLoader from "react-spinners/BeatLoader";


import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    RadialLinearScale,
    Filler,
  } from 'chart.js';
import { Line, Radar } from 'react-chartjs-2';
import { fakerDE as faker } from '@faker-js/faker';


const Stats = () => {


  const radarData = {
    labels: ['수학1', '수학2', '미분', '적분', '확률과 통계', '기하'],
    datasets: [
      {
        label: '성취도',
        data: [2, 9, 3, 5, 2, 3],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };
  interface StatsModel {
    score: number,
    ranking: number,
    score_upside: number,
    ranking_upside: number,
  }
  const navigate = useNavigate();
  const [stats, setStats] = useState<StatsModel>()
  const [charts, setCharts] = useState<Number[]>([])
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    RadialLinearScale,
    Filler,
    Title,
    Tooltip,
    Legend
  ); 
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: '평균 정답률',
      },
    },
  };

  const labels = [ '일', '월', '화', '수', '목', '금', '토'];
  const data = {
    labels,
    datasets: [
      // {
      //   label: '이론 학습',
      //   data: labels.map(() => faker.datatype.number({ min: 50, max: 100 })),
      //   borderColor: 'rgb(255, 99, 132)',
      //   backgroundColor: 'rgba(255, 99, 132, 0.5)',
      // },
      {
        label: '문제 유형 풀기',
        data: charts,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };
  // const handleIntroPage = () => {
  //   window.location.href = 'https://lambda-services.notion.site/MathExodus-daa273193d07458196f5b07b39b55d6f';
  // };

  const handleIntroPage = (e) => {
    navigate('/league');
  };

  useEffect(() => {
    let url = `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/session/study_stats/?token=${localStorage.getItem('token')}`;
    axios
      .get(url)
      .then((response) => {
      setStats(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    let url = `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/session/study_chart/?token=${localStorage.getItem('token')}`;
    axios
      .get(url)
      .then((response) => {
       setCharts(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const bg = useColorModeValue('white', 'gray.800');

  return (
    <Grid
      templateColumns={{ md: "1fr", lg: "1fr" }}
    templateRows={{ md: "1fr auto", lg: "1fr" }}
    justifyContent={{ md: "center", lg: "center", xl: "center" }}
    my="26px"
    gap="24px"
    padding="5"
    marginTop={0}

  >
    <HStack mb={4}>
        <Heading size='md'>나의 학습 분석</Heading>
        <Spacer />
        <Icon
          as={CiTrophy}
          boxSize={30} 
          onClick={handleIntroPage}
        />
      </HStack>

      {/* <Center>
        <Box textAlign="center" p={5} bg={useColorModeValue('gray.100', 'gray.700')} borderRadius="lg">
          <HStack><InfoIcon color={useColorModeValue('blue.500', 'blue.300')} /><Spacer/></HStack> 
          <Text fontSize="sm">이 페이지는 현재 준비 중입니다.</Text>
          <Text fontSize="sm">더 많은 정보와 업데이트를 위해 사전 신청해 주세요!</Text>
        </Box>
      </Center> */}
      <VStack
  spacing={4}
  align='stretch'
>

<Card variant={"filled"} padding={5}>
<StatGroup>
            <Stat>
              <StatLabel>예측 상위 백분율</StatLabel>
              {stats ? 
               <>
               <StatNumber>{stats.ranking}%</StatNumber>
               <StatHelpText>
                 <StatArrow
                   type={
                     stats.ranking_upside <= 0 ? "increase" : "decrease"
                   }
                 />
                 {Math.abs(100-stats.ranking_upside)}%
               </StatHelpText>
             </> : 
                <Center><BeatLoader /></Center>
              }
            </Stat>

            <Stat>
              <StatLabel>예측 점수</StatLabel>
              {stats ? 
                <>
                  <StatNumber>{stats.score}</StatNumber>
                  <StatHelpText>
                    <StatArrow type={stats.score_upside >= 0 ? 'increase' : 'decrease'} />
                    {Math.abs(stats.score_upside)}점
                  </StatHelpText>
                </> : 
                <Center><BeatLoader /></Center>
              }
            </Stat>
          </StatGroup>
        </Card>

        <Card variant={"outline"}>
          <CardHeader>
            <Heading size='md' mb={4}>정답률 추이</Heading>
          </CardHeader>
          <CardBody>
            <Line height='150' options={options} data={data} />
          </CardBody>
        </Card>

        {/* <Card variant={"outline"}>
          <CardHeader>
            <Heading size='md' mb={4}>성취도 분석</Heading>
          </CardHeader>
          <CardBody>
            <Radar data={radarData} />
          </CardBody>
        </Card> */}
      </VStack>
    </Grid>
  );
};

export default Stats;
