import React, { useState, useEffect } from "react";
import axios from "axios";
import { 
    
    Input, Button, Grid, Heading, HStack, Center, Highlight, useToast, Card, CardBody, Text, Badg, VStack,

    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatArrow,
    StatGroup,
} from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import { ArrowForwardIcon } from '@chakra-ui/icons'
import BeatLoader from "react-spinners/BeatLoader";


function EventEngage() {
  const [email, setEmail] = useState('');
  const toast = useToast(); // useToast Hook을 사용하여 toast 인스턴스 생성
  const navigate = useNavigate();
  const [stats, setStats] = useState();


  useEffect(() => {
    let url_stats = `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/session/study_stats/?token=${localStorage.getItem('token')}`;
        axios
          .get(url_stats)
          .then((response) => {
          setStats(response.data);
            console.log(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
    }, []);


  const RelatedProblem = (e) => {
    navigate('/problem-result', 
    { state: {problem_type: 'weak'} }
    );
  };

  return (
    <Grid
      templateColumns={{ md: "1fr", lg: "1fr" }}
      templateRows={{ md: "1fr auto", lg: "1fr" }}
      justifyContent={{ md: "center", lg: "center", xl: "center" }}
      my="26px"
      gap="24px"
      padding="5"
    >
       <Heading lineHeight='tall'>
        <Highlight
          query={['이벤트']}
          styles={{ px: '2', py: '1', rounded: 'full', bg: 'blue.100' }}
        >
          성공적으로 이벤트 참여 신청이 완료되었습니다!
        </Highlight>
      </Heading>
      <VStack align='stretch' spacing={4}>
<Text color={"gray"} align={"left"} fontSize='sm'  as='sub'>나의 현재 랭킹</Text>

{/* <Stepper size='sm' index={activeStep}>
      {steps.map((step, index) => (
        <Step key={index}>
          <StepIndicator>
            <StepStatus
              complete={<StepIcon />}
              incomplete={<StepNumber />}
              active={<StepNumber />}
            />
          </StepIndicator>

          <Box flexShrink='0'>
            <StepTitle>{step.title}</StepTitle>
            <StepDescription>{step.description}</StepDescription>
          </Box>

          <StepSeparator />
        </Step>
      ))}
    </Stepper> */}
<Center>
<Card variant={"filled"} padding={5}
    width='330px'
    >
<StatGroup>
            <Stat>
              <StatLabel>예측 상위 등수</StatLabel>
              {stats ? 
                <>
                  <StatNumber>{stats.ranking}</StatNumber>
                  <StatHelpText>
                    <StatArrow type={stats.ranking_upside >= 0 ? 'increase' : 'decrease'} />
                    {Math.abs(stats.ranking_upside)}등
                  </StatHelpText>
                </> : 
                <Center><BeatLoader /></Center>
              }
            </Stat>

            <Stat>
              <StatLabel>예측 점수</StatLabel>
              {stats ? 
                <>
                  <StatNumber>{stats.score}</StatNumber>
                  <StatHelpText>
                    <StatArrow type={stats.score_upside >= 0 ? 'increase' : 'decrease'} />
                    {Math.abs(stats.score_upside)}점
                  </StatHelpText>
                </> : 
                <Center><BeatLoader /></Center>
              }
            </Stat>
          </StatGroup>
        </Card>
        </Center>
    </VStack>
      <Button rightIcon={<ArrowForwardIcon />} onClick={RelatedProblem} colorScheme='blue' variant='outline'>지금 바로 문제 풀러 가기</Button>

      {/* <HStack> */}
      {/* </HStack> */}
      {/* <Center>
        <Button
          size='md'
          height='48px'
          width='300px'
          colorScheme='blue'
          onClick={handleIntroPage}
        >
          MathExodus 소개 페이지
        </Button>
      </Center> */}
    </Grid>  
  );
}

export default EventEngage;
