import React, { useEffect, useRef, useState } from "react";
import { loadPaymentWidget, ANONYMOUS } from "@tosspayments/payment-widget-sdk";
import { nanoid } from "nanoid";
import { Input, Button, Grid, Heading, HStack, Center, Highlight, useToast, Text, Card, Badge, CardBody } from '@chakra-ui/react';



const selector = "#payment-widget";

const widgetClientKey = "live_gck_ma60RZblrqyBGxnDkvWbrwzYWBn1";
const customerKey = window.localStorage.getItem("token");

export function CheckoutMonthPage() {
  const [paymentWidget, setPaymentWidget] = useState(null);
  const paymentMethodsWidgetRef = useRef(null);
  const [price, setPrice] = useState(3000);

  useEffect(() => {
    const fetchPaymentWidget = async () => {
      try {
        const loadedWidget = await loadPaymentWidget(
          widgetClientKey,
          customerKey
        );
        setPaymentWidget(loadedWidget);
      } catch (error) {
        console.error("Error fetching payment widget:", error);
      }
    };

    fetchPaymentWidget();
  }, []);

  useEffect(() => {
    if (paymentWidget == null) {
      return;
    }

    const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
      selector,
      { value: price },
      { variantKey: "DEFAULT" }
    );

    paymentWidget.renderAgreement("#agreement", { variantKey: "AGREEMENT" });

    paymentMethodsWidgetRef.current = paymentMethodsWidget;
  }, [paymentWidget, price]);

  useEffect(() => {
    const paymentMethodsWidget = paymentMethodsWidgetRef.current;

    if (paymentMethodsWidget == null) {
      return;
    }

    paymentMethodsWidget.updateAmount(price);
  }, [price]);

  const handlePaymentRequest = async () => {
    // TODO: 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
    // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
    

    try {
      await paymentWidget?.requestPayment({
        // orderId: nanoid(),
        // orderName: "토스 티셔츠 외 2건",
        // customerName: "김토스",
        // customerEmail: "customer123@gmail.com",
        // customerMobilePhone: "01012341234",
        // successUrl: `${window.location.origin}/success`,
        // failUrl: `${window.location.origin}/fail`,
        orderId: nanoid(),
        orderName: "한달 이용권",
        customerName: "비회원",
        successUrl: `${window.location.origin}/success`,
        failUrl: `${window.location.origin}/fail`,
      });
    } catch (error) {
      console.error("Error requesting payment:", error);
    }
  };

  return (
    <Grid
      templateColumns={{ md: "1fr", lg: "1fr" }}
      templateRows={{ md: "1fr auto", lg: "1fr" }}
      justifyContent={{ md: "center", lg: "center", xl: "center" }}
      my="26px"
      gap="24px"
      padding="5"
    >
      <Heading lineHeight='tall'>
        <Highlight
          query={['사전 신청', '혜택']}
          styles={{ px: '2', py: '1', rounded: 'full', bg: 'blue.100' }}
        >
         상품 정보
        </Highlight>
      </Heading>
      <HStack>     
      </HStack>
      <Card variant={"outline"} borderColor={"grey"}>
  <CardBody>
  <HStack>
    <Badge >1달 구독권 </Badge>
    <Badge >3,000₩</Badge>
    </HStack>
  </CardBody>
</Card>
      <div className="wrapper">
      <div className="box_section">
        {/* 결제 UI, 이용약관 UI 영역 */}
        <div id="payment-widget" />
        <div id="agreement" />
        <div className="result wrapper">
          {/* 결제하기 버튼 */}
          <Button
            className="button"
            style={{ marginTop: "30px" }}
            onClick={handlePaymentRequest}
          >
            결제하기
            </Button>
            <br/><br/>
        </div>
      </div>
      <br/><br/>
      <br/><br/>
      <br/><br/>

    </div>
    </Grid> 
    
  );
}
