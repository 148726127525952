import { Route, Routes } from "react-router-dom";
import "./App.css";
import { Problem } from "./pages/Problem";
import { Theory } from "./pages/Theory";
import Stats from "./pages/Stats";
import Ranking from "./pages/Ranking";
import Billing from "./pages/Billing";
import ProblemType from "./pages/ProblemType";
import { TheoryType } from "./pages/TheoryType";
import BillingLanding from "./pages/BillingLanding"
import BillingFree from "./pages/BillingFree"
import ChatLanding from "./pages/ChatLanding"
import Preservices from "./pages/Preservices";
import OnBoards from "./OnBoards";
import ProblemCapture from "./pages/ProblemCapture";
import ProblemCaptureResult from "./pages/ProblemCaptureResult";
import { CheckoutMonthPage } from "./payments/CheckoutMonth.jsx";
import { CheckoutYearPage } from "./payments/CheckoutYear.jsx";
import { SuccessPage } from "./payments/Success.jsx";
import { FailPage } from "./payments/Fail.jsx";
import Refund from "./pages/Refund.jsx"
import EventEngage from "./pages/EventEngage.jsx"
import EventEngageSuccess from "./pages/EventEngageSuccess.jsx"
import EventDescription from "./pages/EventDescription.jsx"
import PrivacyPolicy from "./pages/PrivacyPolicy.jsx"
import SubsCribe from "./pages/Subscribe.jsx"
import { EngageProblem } from "./pages/EngageProblem.jsx"
import { EngageTheory } from "./pages/EngageTheory.jsx"
import ProblemSolution from "./pages/ProblemSolution"
import PolicyView from "./pages/PolicyView.js"
import League from "./pages/League.jsx"



export function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={window.localStorage.getItem("token") ? < Problem /> : <Problem/> } />
        <Route path="/problem" element={<Problem />} />
        <Route path="/theory" element={<Theory />} />
        <Route path="/stats" element={<Stats />} />
        {/* <Route path="/ranking" element={<Ranking />} /> */}
        <Route path="/ranking" element={<ProblemCapture />} />
        <Route path="/billing" element={window.localStorage.getItem("subscription") ? <SubsCribe/> : <Billing /> } />
        <Route path="/problem-result" element={<ProblemType />} />
        <Route path="/theory-type" element={<TheoryType />} />
        <Route path="/billing-landing" element={<BillingLanding />} />
        <Route path="/billing-free" element={<BillingFree />} />
        <Route path="/chaticon-landing" element={<ChatLanding />} />
        <Route path="/preservices" element={<Preservices />} />
        <Route path="/create-solution-result" element={<ProblemCaptureResult />} />
        <Route path="/checkout-month" element={<CheckoutMonthPage/>} />
        <Route path="/checkout-year" element={<CheckoutYearPage/>} />
        <Route path="/success" element={<SuccessPage />} />
        {/* <Route path="/event-engage" element={<EventEngage />} /> */}
        <Route path="/event-engage" element={window.localStorage.getItem("eventEngage") ? <EventEngageSuccess /> : <EventEngage />} />
        <Route path="/event-engage-success" element={<EventEngageSuccess />} />
        <Route path="/fail" element={<FailPage />} />
        <Route path="/event" element={<EventDescription />} />
        <Route path="/refund" element={<Refund />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
        <Route path="/engage-problem" element={<EngageProblem/>} />
        <Route path="/engage-theory" element={<EngageTheory/>} />
        <Route path="/problem-solution-result" element={<ProblemSolution/>} />
        <Route path="/policy" element={<PolicyView/>} />
        <Route path="/league" element={<League/>} />


      </Routes>
    </div>
  );
}

// import React from "react";
// import { createBrowserRouter, RouterProvider } from "react-router-dom";
// // import "./App.css";
// import { CheckoutPage } from "./payments/Checkout.jsx";
// import { TheoryType } from "./pages/TheoryType";
// import { Problem } from "./pages/Problem";
// import { Theory } from "./pages/Theory";

// import BillingLanding from "./pages/BillingLanding"
// import BillingFree from "./pages/BillingFree"
// import ChatLanding from "./pages/ChatLanding"
// // import { SuccessPage } from "./Success.jsx";
// // import { FailPage } from "./Fail.jsx";

// const router = createBrowserRouter([
//   {
//     path: "checkout",
//     element: <CheckoutPage />,
//   },

//   {
//     path: "problem",
//     element: <Problem />,
//   },  {
//     path: "theory-type",
//     element: <TheoryType />,
// //         <Route path="/theory" element={<Theory />} />
//   },
//   {
//     path: "theory",
//     element: <Theory />,
//   }, 

//   // {
//   //   path: "success",
//   //   element: <SuccessPage />,
//   // },
//   // {
//   //   path: "fail",
//   //   element: <FailPage />,
//   // },
// ]);

// export function App() {
//   return <RouterProvider router={router} />;
// }
