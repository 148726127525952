import React, { FC, useEffect, useState } from "react";
import {
    Flex,
    Grid,
    Image,
    SimpleGrid,
    useColorModeValue,
    IconButton,
    ButtonGroup,
    SkeletonCircle,
    SkeletonText,
    Box,
    Spacer,
  } from "@chakra-ui/react";
  
import axios from "axios";
import BeatLoader from "react-spinners/BeatLoader";
import { ChatIcon, InfoIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom';  

import { MdBookmark, MdBookmarkBorder, MdRestartAlt} from "react-icons/md";

import { useLocation } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { Divider } from '@chakra-ui/react'
import { Stack, HStack, VStack } from '@chakra-ui/react'
import { Text } from '@chakra-ui/react'
import { Card, CardHeader, CardBody, CardFooter } from '@chakra-ui/react'
import { Heading } from '@chakra-ui/react'

interface IResponseObject {
    content: string;
}

const ProblemSolution: FC = () => {
    const [response, setResponse] = useState<IResponseObject>({ content: "" });
    const location = useLocation();
    const [complete, setComplete] = useState(false);
    const [isBookmark, setBookmark] = useState(false);


    console.log(location.state.problem_id)
    console.log(location.state.html_text)
    const navigate = useNavigate();

    const handleIntroPage = (e) => {
      navigate('/chaticon-landing');
    };

    function clickBookmark(){
      setBookmark((prevbookmark) => { 
          if (prevbookmark) {
              let url = `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/bookmarks/?problem_id=${location.state.problem_id}&bookmark=${prevbookmark}&token=${window.localStorage.getItem('token')}`;
              axios.patch(url)
              console.log(prevbookmark)
              return false
  
          }
          else {
              let url = `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/bookmarks/?problem_id=${location.state.problem_id}&bookmark=${prevbookmark}&token=${window.localStorage.getItem('token')}`;
              axios.patch(url)
              console.log(prevbookmark)
              return true
          }
        })
  }
    
    useEffect(() => {
        const eventSource = new EventSource(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/problem/solution?problem_id=${location.state.problem_id}`);

        eventSource.onmessage = (event) => {
            const responseObject = event.data;
            console.log(event.data);

            setResponse((prev: IResponseObject) => {
                console.log(event.data);
                const responseObjectContent = responseObject || null;
                const safeHTML = DOMPurify.sanitize(responseObjectContent);

                const combinedObject = {
                    content: safeHTML,
                };
                return combinedObject;
            });

        };

   

        eventSource.onerror = (error) => {
            console.log("Error with SSE connection:", error);
            setComplete((prevcompelete) => { return true})
            // post 해설지 업데이트 하기

            eventSource.close(); // Also close on error
        };

        // This will close the connection when the component unmounts
        return () => {
            eventSource.close();
            // post 업데이트 하기
            setComplete((prevcompelete) => { return true})
        };
    }, [location.state.problem_id]); // Add dependency to useEffect

    return (
      <Grid templateColumns="repeat(1, 1fr)" gap={6} padding={5}>
        <HStack > 
        <Heading textAlign={"left"} size='md'>AI 문제 해설지</Heading>
          <Spacer />

           <IconButton
             onClick={handleIntroPage}
             isRound={true}
             variant='solid'
             colorScheme='blue'
          aria-label='Send email'
          icon={<ChatIcon />}
        /></HStack>
      <VStack spacing={5} align="stretch">
      <Card align="center" variant="filled" colorScheme="blue">
            <CardBody>
            <IconButton 
              fontSize='30px'
              onClick={clickBookmark} aria-label='Book Mark'
               icon={isBookmark ? <MdBookmark />: <MdBookmarkBorder/>}  /> 
            <Image src={location.state.problem_image} borderRadius="lg" />
            </CardBody>
          </Card>

        <Box p={5} borderWidth='1px' borderRadius='lg'>
          <Divider mb={3}/>
          {response.content ? 
            <Text dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(response.content) }} /> : 
            <BeatLoader />
          }
        </Box>
        <br/>
        <br/>
        {/* <ButtonGroup isAttached variant="outline">
          <IconButton
            icon={isBookmark ? <MdBookmark /> : <MdBookmarkBorder />}
            onClick={clickBookmark}
            isDisabled={!complete}
            aria-label="Bookmark"
          />
          <IconButton
            icon={<MdRestartAlt />}
            isDisabled={!complete}
            aria-label="Repeat"
          />
        </ButtonGroup> */}
      </VStack>
    </Grid>
  );
};

export default ProblemSolution;