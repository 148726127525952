import React, { useState } from "react";
import axios from "axios";
import { Input, Button, Grid, Heading, HStack, Center, Highlight, useToast, Card, CardBody, Text, Badge } from '@chakra-ui/react';

function Ranking() {
  const [email, setEmail] = useState('');
  const toast = useToast(); // useToast Hook을 사용하여 toast 인스턴스 생성

  const handleSubmit = () => {
    const apiUrl = `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/email-subscription/`; // Django API 엔드포인트로 변경하세요.
    axios.post(apiUrl, { email: email })
      .then(response => {
        // 성공적으로 제출되었을 때의 로직
        console.log('Email submitted successfully:', response.data);
        toast({ // 성공 메시지 팝업
          description: "사전 신청이 등록되었어요",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
          colorScheme: "blue"
        });
      })
      .catch(error => {
        // 오류 처리 로직
        console.error('There was an error submitting the email:', error);
        toast({ // 오류 메시지 팝업
          description: "이미 사전 신청된 번호입니다.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      });
  };

  const handleIntroPage = () => {
    window.location.href = 'https://lambda-services.notion.site/MathExodus-daa273193d07458196f5b07b39b55d6f';
  };

  return (
    <Grid
      templateColumns={{ md: "1fr", lg: "1fr" }}
      templateRows={{ md: "1fr auto", lg: "1fr" }}
      justifyContent={{ md: "center", lg: "center", xl: "center" }}
      my="26px"
      gap="24px"
      padding="5"
    >
      <Heading lineHeight='tall'>
        <Highlight
          query={['문제', '보상']}
          styles={{ px: '2', py: '1', rounded: 'full', bg: 'blue.100' }}
        >
          지금 바로 문제를 풀고 보상을 받으세요!
        </Highlight>
      </Heading>

      <HStack>
      <Card variant={"filled"} borderColor={"blue.500"}>
        <CardBody>
          <HStack>
            <Text>나의 식별코드: </Text>
          <Badge colorScheme='blue'>{window.localStorage.getItem("token").slice(0,5)}</Badge>
          </HStack>
        </CardBody>
      </Card>
      
        <Input placeholder='기프티콘을 받으실 휴대폰 번호를 입력해주세요!' size='sm' value={email} onChange={(e) => setEmail(e.target.value)} />
        <Button colorScheme='blue' variant='outline' onClick={handleSubmit}>
          제출
        </Button>
      </HStack>
      <Center>
        <Button
          size='md'
          height='48px'
          width='300px'
          colorScheme='blue'
          onClick={handleIntroPage}
        >
          MathExodus 소개 페이지
        </Button>
      </Center>
    </Grid>  
  );
}

export default Ranking;
