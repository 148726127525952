import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { 
    
  Input, Button, Grid, Heading, HStack, Center, Highlight, useToast, Card, CardBody, Text, Badg, VStack,

  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
} from '@chakra-ui/react';

import { ChatIcon, ArrowForwardIcon } from '@chakra-ui/icons'


export function EngageProblem() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const MonoTypeProblem = (e) => {
    navigate('/problem-result', 
    { state: {problem_type: 'mono'} }
    );
  };

  return (
    <Grid
      templateColumns={{ md: "1fr", lg: "1fr" }}
      templateRows={{ md: "1fr auto", lg: "1fr" }}
      justifyContent={{ md: "center", lg: "center", xl: "center" }}
      my="26px"
      gap="24px"
      padding="5"
    >
       <Heading lineHeight='tall'>        
          아직 해당하는 문제가 없어요!
      </Heading>
    <div className="result wrapper">
      <div className="box_section">
        <h2 style={{ padding: "20px 0px 10px 0px" }}>
          <Center>
          <img
            width="50px"
            src="https://static.toss.im/3d-emojis/u1F389_apng.png"
          />
          </Center>
          <Center>
<Button 
  size='md'
  height='48px'
  width='300px'
  variant='link'
  colorScheme='blue'
  borderRadius={'3xl'}
  onClick={MonoTypeProblem} 
  rightIcon={<ArrowForwardIcon />} 
>
 문제 더 풀어보러 가기
</Button>
</Center>
        </h2>
        {/* <p>{`주문번호: ${searchParams.get("orderId")}`}</p> */}
      
        {/* <p>{`paymentKey: ${searchParams.get("paymentKey")}`}</p> */}
      </div>
    </div>
    </Grid>  

  );
}
