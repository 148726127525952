import React from "react";
import ReactDOM from "react-dom";
// import "./index.css";
import { App } from "./App";
import BottomNav from "./BottomNav";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider, theme } from "@chakra-ui/react";


ReactDOM.render(
  <React.StrictMode>
        <ChakraProvider theme={theme}>
        <BrowserRouter>
        <App />
        {/* {window.localStorage.getItem("token")?  <BottomNav /> : <></> } */}
        </BrowserRouter>
    </ChakraProvider>

  </React.StrictMode>,
  document.getElementById("root")
);