import React from 'react';
import {
  Box,
  Text,
  VStack,
  Heading,
  Link,
  Container,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';

const PrivacyPolicy = () => {
  return (
    <Container maxW="container.md" p={4}>
      <VStack spacing={5} align="stretch">
        <Heading as="h1" size="lg">개인정보 처리방침</Heading>
        
        <Text>람다 서비스는 고객님들의 소중한 개인정보 보호를 위해 아래와 같은 방침을 수행하고 있습니다.</Text>

        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  1. 개인정보의 처리 목적
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
            (주)람다서비스(‘mathexdous.com’이하 ‘람다 서비스’) 은 다음의 목적을 위하여 개인정보를 처리하고 있으며, 다음의 목적 이외의 용도로는 이용하지 않습니다.
– 고객 가입의사 확인, 고객에 대한 서비스 제공에 따른 본인 식별.인증, 회원자격 유지.관리, 물품 또는 서비스 공급에 따른 금액 결제, 물품 또는 서비스의 공급.배송 등
            </AccordionPanel>
          </AccordionItem>

          {/* 이하 동일한 방식으로 나머지 섹션 추가 */}

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  2. 개인정보의 처리 및 보유 기간
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
            <Text>① ‘람다 서비스’은 정보주체로부터 개인정보를 수집할 때 동의 받은 개인정보 보유․이용기간 또는 법령에 따른 개인정보 보유․이용기간 내에서 개인정보를 처리․보유합니다.</Text>
            <Text>② 구체적인 개인정보 처리 및 보유 기간은 다음과 같습니다.</Text>
– 고객 가입 및 관리 : 카카오싱크를 통한 회원가입 및 카카오채널을 통한 관리
– 보유 기간 : 구독 탈퇴 후 즉시 삭제            
</AccordionPanel>
          </AccordionItem>
          
          {/* 정보주체 권리, 개인정보 항목, 파기 절차 등 섹션 추가 */}
          
        </Accordion>
        
        <Box>
          <Text as="i">본 개인정보 처리방침은 2024년 3월 18일부터 적용됩니다.</Text>
        </Box>
      </VStack>
    </Container>
  );
};

export default PrivacyPolicy;
