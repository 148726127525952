import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  Grid,
  Heading,
  HStack,
  Spacer,
  IconButton,
  Box,
  Center,
  Text,
  useColorModeValue,
  SimpleGrid,
  Card, CardHeader, CardBody, CardFooter, Button, Icon,Badge, useDisclosure

} from "@chakra-ui/react";
import {
  Table,
  TableCaption,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";

import { ChatIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { CgAlbum, CgBot, CgDribbble, CgPen, CgBee, CgTree, CgTrees, CgLock, CgPiano} from "react-icons/cg";

function League() {
  const navigate = useNavigate();

  const handleIntroPage = () => {
    navigate("/chaticon-landing");
  };
  const [loading, setLoading] = useState(true);

  const [sessionRanking, setSessionRanking] = useState(null);
  const [sessionPoints, setSessionPoints] = useState(null);
  const [rankingList, setRankingList] = useState([]);
  const token = localStorage.getItem("token"); // 세션 토큰을 가져옵니다. 로컬 스토리지에서 가져올 수도 있습니다.
  const { isOpen, onOpen, onClose } = useDisclosure();


  useEffect(() => {
    const fetchSessionRankingAndPoints = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/points/?token=${localStorage.getItem('token')}`  // 해당 엔드포인트는 Django 포인트 뷰와 연결됩니다.
        );
        setSessionRanking(response.data.rank);
        setSessionPoints(response.data.points);
      } catch (error) {
        console.error("Error fetching session ranking and points:", error);
      }
       finally {
        setLoading(false); // 로딩이 완료되면 false로 설정
      }
    };

    fetchSessionRankingAndPoints();

    const fetchRankingList = async () => {
        try {
          const response = await axios.get( `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/points/list?token=${token}`);  // 위에서 만든 API의 엔드포인트
          setRankingList(response.data.ranking_list);
        } catch (error) {
          console.error("Error fetching ranking list:", error);
        }
      };
  
      if (token) { // 토큰이 있을 때에만 랭킹 리스트를 가져옵니다.
        fetchRankingList();
      }
  }, []);

  const RecomendTypeProblem = (e) => {
    navigate("/problem-result", { state: { problem_type: "recommend" } });
  };

  return (
    <Grid
    templateColumns={{ md: "2fr", lg: "1fr" }}
    templateRows={{ md: "1fr auto", lg: "1fr" }}
    justifyContent={{ md: "center", lg: "center", xl: "center" }}
    my="26px"
    gap="24px"
    padding="5"
    marginTop={0}
    >

      <HStack > 
        <Heading textAlign={"left"} size='md'>리그 랭킹</Heading>
          <Spacer />

           {/* <IconButton
             onClick={handleIntroPage}
             isRound={true}
             variant='solid'
             colorScheme='blue'
          aria-label='Send email'
          icon={<ChatIcon />}
        /> */}
        
        </HStack>

<Card variant={"filled"} borderColor={"blue.500"}>
        <CardBody>
          <HStack>
            <Text>나의 ID: </Text>
          <Badge colorScheme='blue'>{window.localStorage.getItem("token").slice(0,5)}</Badge>
          </HStack>
        </CardBody>
      </Card>
<Card variant={"filled"} padding={5}>
<StatGroup>
            <Stat>
              <StatLabel>리그 랭킹</StatLabel>
              {sessionRanking ? 
                <>
                  <StatNumber>{sessionRanking}</StatNumber>
                </> : 
                <Center><BeatLoader /></Center>
              }
            </Stat>

            <Stat>
              <StatLabel>나의 포인트</StatLabel>
              {sessionPoints ? 
                <>
                  <StatNumber>{sessionPoints}</StatNumber>
                  
                </> : 
                <Center><BeatLoader /></Center>
              }
            </Stat>
          </StatGroup>
        </Card>
        {rankingList.length > 0 ? 
        <TableContainer>
      <Table variant="striped" colorScheme="blue">
        <Thead>
          <Tr>
            <Th>랭킹</Th>
            <Th>ID</Th>
            <Th isNumeric>포인트</Th>
          </Tr>
        </Thead>
       
        <Tbody>
        {rankingList.map((item, index) => (
            <Tr key={index}>
              <Td>{item.rank}</Td>
              <Td>{item.session_token.slice(0,5)}</Td>
              <Td isNumeric>{item.points}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>: <Center><BeatLoader /></Center>}
      <SimpleGrid spacing={4} templateColumns='repeat(auto-fill, minmax(150px, 1fr))'>
         <Card variant={"filled"}       
          color="blue.500"
          onClick={RecomendTypeProblem} 
            >
          <CardHeader>
            <Heading size='md' textAlign={"left"}> Level 1.  </Heading>
          </CardHeader>
          <CardBody align={"left"}>
          <Icon as={CgPen} boxSize={30}/>
          </CardBody>
          <CardFooter textAlign={"left"}>
          {/* <Tooltip hasArrow label='곧 출시 예정입니다!' bg='blue.500'>
          <Button leftIcon={<LockIcon/>}variant='ghost' colorScheme="gray">학습하기</Button>
          </Tooltip>           */}
          </CardFooter>
        </Card>
        <Card variant={"filled"}       
          color= {sessionPoints < 50? "gray": "blue.500"} 
          onClick={sessionPoints < 50? onOpen: RecomendTypeProblem}

            >
          <CardHeader>
            <Heading size='md' textAlign={"left"}> Level 2.</Heading>
          </CardHeader>
          <CardBody align={"left"}>
          {sessionPoints < 50 ?<Icon as={CgLock} boxSize={30} colorScheme="gray"/> :
           <Icon as={CgDribbble} boxSize={30}/>}
          {/* <MathComponent tex={ String.raw`y=f(x)`} display={false} /> */}
                {/* <Box overflow='hidden'>
              <Text fontSize='md'>
                <div dangerouslySetInnerHTML={{ __html: e.html_text }} />
              </Text>
              </Box> */}
          </CardBody>
          <CardFooter textAlign={"left"}>
          {/* <Tooltip hasArrow label='곧 출시 예정입니다!' bg='blue.500'>
          <Button leftIcon={<LockIcon/>}variant='ghost' colorScheme="gray">학습하기</Button>
          </Tooltip>           */}
          </CardFooter>
        </Card>
         <Card variant={"filled"}  
         color= {sessionPoints < 100? "gray": "blue.500"} 

          onClick={sessionPoints < 100? onOpen: RecomendTypeProblem}
          >
          <CardHeader>
            <Heading size='md' textAlign={"left"}> Level 3.</Heading>
            
          </CardHeader>
          <CardBody align={"left"}>
          {sessionPoints < 100 ?<Icon as={CgLock} boxSize={30} colorScheme="gray"/> :
           <Icon as={CgAlbum} boxSize={30}/>}
          </CardBody>
          <CardFooter>
          {/* <Tooltip hasArrow label='곧 출시 예정입니다!' bg='blue.500'>
          <Button leftIcon={<LockIcon/>}variant='ghost' colorScheme="gray">학습하기</Button>
          </Tooltip> */}
          </CardFooter>
        </Card>
        <Card variant={"filled"} 
            color= {sessionPoints < 200? "gray": "blue.500"} 
            onClick={sessionPoints < 200? onOpen: RecomendTypeProblem}

          // onClick={onOpen}
          >
          <CardHeader>
            <Heading size='md' textAlign={"left"}> Level 4.</Heading>
            
          </CardHeader>
          <CardBody align={"left"}>
          {sessionPoints < 200 ?<Icon as={CgLock} boxSize={30} colorScheme="gray"/> :
           <Icon as={CgBot} boxSize={30}/>}
        </CardBody>
          <CardFooter>
          {/* <Tooltip hasArrow label='곧 출시 예정입니다!' bg='blue.500'>
          <Button leftIcon={<LockIcon/>}variant='ghost' colorScheme="gray">학습하기</Button>
          </Tooltip> */}
          </CardFooter>
        </Card>

        <Card variant={"filled"} 
           color= {sessionPoints < 300? "gray": "blue.500"} 
           onClick={sessionPoints < 300? onOpen: RecomendTypeProblem}
            // onClick={onOpen}
        >
          <CardHeader>
            <Heading size='md' textAlign={"left"}> Level 5. </Heading>
          </CardHeader>
          <CardBody align={"left"}>
          {sessionPoints < 300 ?<Icon as={CgLock} boxSize={30} colorScheme="gray"/> :
           <Icon as={CgPiano} boxSize={30}/>}
                 </CardBody>
          <CardFooter>
          {/* <Tooltip hasArrow label='곧 출시 예정입니다!' bg='blue.500'>
          <Button leftIcon={<LockIcon/>}variant='ghost' colorScheme="gray">학습하기</Button>
          </Tooltip> */}
          </CardFooter>
        </Card>
        <Card variant={"filled"} 
          color= {sessionPoints < 400? "gray": "blue.500"}
          onClick={sessionPoints < 400? onOpen: RecomendTypeProblem}

        >
          <CardHeader>
            <HStack ><Heading size='md' textAlign={"left"}> Level 6.  </Heading>
            {/* <Badge ml='1' colorScheme='blue'>
          New
          </Badge> */}
          </HStack>
          </CardHeader>
          <CardBody align={"left"}>
          {sessionPoints < 400 ?<Icon as={CgLock} boxSize={30} colorScheme="gray"/> :
           <Icon as={CgBee} boxSize={30}/>}
                  </CardBody>
          <CardFooter>
          {/* <Button variant='ghost' colorScheme="blue">학습하기</Button> */}
          </CardFooter>
        </Card>
        <Card variant={"filled"}
                 color= {sessionPoints < 500? "gray": "blue.500"} 
                 onClick={sessionPoints < 500? onOpen: RecomendTypeProblem}

                  // onClick={onOpen}
        >
          <CardHeader>
            <Heading size='md' textAlign={"left"}> Level 7. </Heading>
          </CardHeader>
          <CardBody align={"left"}>
          {sessionPoints < 500 ?<Icon as={CgLock} boxSize={30} colorScheme="gray"/> :
           <Icon as={CgTree} boxSize={30}/>}
              </CardBody>
          <CardFooter>
          {/* <Tooltip hasArrow label='곧 출시 예정입니다!' bg='blue.500'>
          <Button leftIcon={<LockIcon/>}variant='ghost' colorScheme="gray">학습하기</Button>
          </Tooltip> */}
                    </CardFooter>
        </Card>
        <Card variant={"filled"} 
         color= {sessionPoints < 600? "gray": "blue.500"} 
         onClick={sessionPoints < 600? onOpen: RecomendTypeProblem}

        >
          <CardHeader>
            <Heading size='md' textAlign={"left"}> Level 8.  </Heading>
          </CardHeader>
          <CardBody align={"left"}>
          {sessionPoints < 600 ?<Icon as={CgLock} boxSize={30} colorScheme="gray"/> :
           <Icon as={CgTrees} boxSize={30}/>}
          </CardBody>
          <CardFooter textAlign={"left"}>
                    </CardFooter>
        </Card>
        
        <br/><br/>
        <br/><br/>
    </SimpleGrid>
  
    </Grid>


     
  );
}

export default League;
