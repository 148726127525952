import React from "react";
import { MathComponent } from "mathjax-react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Text,
  Heading,
  Button,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Box,
  Grid,
  SimpleGrid,
  VStack,
  HStack,
  Stack,
  Skeleton,
  Center,
  RadioGroup,
  Spacer,
  Radio,
  Badge,
  Drawer,
  IconButton,
} from "@chakra-ui/react";
import { useToast } from '@chakra-ui/react'

import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css";
import { ArrowForwardIcon, CheckCircleIcon } from "@chakra-ui/icons";
import {
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

import {
  GiBiceps,
  GiArcheryTarget,
  GiBookshelf,
  GiBookmark,
} from "react-icons/gi";
import BeatLoader from "react-spinners/BeatLoader";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { MdBookmark, MdBookmarkBorder, MdRestartAlt} from "react-icons/md";


export function TheoryType() {
  const navigate = useNavigate();
  const [theory, setTheory] = useState();
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [placement, setPlacement] = React.useState("right");
  const [isBookmark, setBookmark] = useState(false);
  const toast = useToast()

  console.log(location.state.theory_type);

  useEffect(() => {

    if (location.state.theory_type==='bookmarks') {
      let url = `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/bookmarks/theory?token=${window.localStorage.getItem('token')}`;
      axios.get(url)
        .then(response => {
          setTheory(response.data);
        })
        .catch(error => {
          console.log(error);
          navigate('/engage-theory')
        });
      setBookmark(true)
    }

    else {

    let url = `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/theory/?theory_type=${location.state.theory_type}`;
    axios
      .get(url)
      .then((response) => {
        setTheory(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    }

    toast({
      title: '8포인트를 획득하셨어요!',
      position: 'top',
      description: "이론을 더 공부하고 포인트를 더 획득하세요!",
      status: 'info',
      duration: 1500,
      isClosable: true,
    })

    const pointUrl = `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/points/`; // Django API 엔드포인트로 변경하세요.
    axios.post(pointUrl, 
      { 
        token: localStorage.getItem('token'),
        point: 8,
        type: 'theory'

         })
      .then(response => {
        // 성공적으로 제출되었을 때의 로직
        console.log('result submitted successfully:', response.data);
      })
      .catch(error => {
        // 오류 처리 로직
        console.error('result', error);
       
      });

  }, [location.state.theory_type]);

  const NextProblem = (e) => {

    console.log("next problem");
    navigate(0);
  };

  const RelatedProblem = (e) => {
    navigate("/problem-result", { state: { problem_type: "weak" } });
  };

  function clickBookmark(){
    setBookmark((prevbookmark) => { 
        if (prevbookmark) {
            let url = `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/bookmarks/theory?theory_id=${theory.id}&bookmark=${prevbookmark}&token=${window.localStorage.getItem('token')}`;
            axios.patch(url)
            console.log(prevbookmark)
            return false

        }
        else {
            let url = `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/bookmarks/theory?theory_id=${theory.id}&bookmark=${prevbookmark}&token=${window.localStorage.getItem('token')}`;
            axios.patch(url)
            console.log(prevbookmark)
            return true
        }
      })
}

  return (
    <Grid
      templateColumns={{ md: "1fr", lg: "1fr" }}
      templateRows={{ md: "1fr auto", lg: "1fr" }}
      justifyContent={{ md: "center", lg: "center", xl: "center" }}
      my="26px"
      gap="24px"
      padding="5"
      marginTop={0}
    >
      {theory ? (
        <VStack spacing={8} align="stretch">
          <VStack align="center" spacing={4}>
            <Card width={window.innerWidth > 500 ? "95%" : "330px"}
             overflow={"scroll"} variant={"filled"} colorScheme="blue">
     
              <CardBody align="right">
              <IconButton 
                fontSize='30px'
              onClick={clickBookmark} aria-label='Book Mark'
               icon={isBookmark ? <MdBookmark />: <MdBookmarkBorder/>}  /> 
                <div
                    style={{
                      textAlign: "left",
                      fontSize: "0.9em", // 글자 크기를 작게 조정
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: theory.html_text }}
                    ></div>
                    <br></br>
                  </div>
              </CardBody>
              <CardFooter>
                {/* <Button colorScheme='blue'>유형 문제 학습하기</Button> */}
              </CardFooter>
            </Card>
          </VStack>

            <VStack direction="row" mb="4" align={"center"}>
              {/* <RadioGroup padding={3} defaultValue={placement} onChange={setPlacement}>
    <Stack direction='row' mb='4'>
          <Radio value='1'>1번</Radio>
          <Radio value='2'>2번</Radio>
          <Radio value='3'>3번</Radio>
          <Radio value='4'>4번</Radio>
          <Radio value='5'>5번</Radio>
        </Stack>
      </RadioGroup> */}
              <Stack align={"center"} direction="row" spacing={4}>
                <Button
                  rightIcon={<ArrowForwardIcon />}
                  onClick={NextProblem}
                  colorScheme="blue"
                  variant="outline"
                >
                  다음 이론 학습
                </Button>
                <Button
                  rightIcon={<ArrowForwardIcon />}
                  onClick={RelatedProblem}
                  colorScheme="blue"
                  variant="outline"
                >
                  관련 문제 풀기
                </Button>
              </Stack>

              {/* <Button colorScheme='blue' onClick={onOpen}>
        제출
      </Button> */}
            </VStack>
        </VStack>
      ) : (
        <Center>
          <BeatLoader />
        </Center>
      )}
    </Grid>
  );
}
